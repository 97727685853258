import * as Sentry from '@sentry/react'

export const initSentry = () => {
  if (process.env.REACT_APP_ENV !== 'development') {
    Sentry.init({
      release: process.env.REACT_APP_VERSION,
      environment: process.env.REACT_APP_ENV,
      dsn: process.env.REACT_APP_SENTRY_DNS ?? '',
    })
  }
}
